<template>
  <div>
    <div v-if="!pod">
      File not found!
    </div>

    <div v-if="pod">

      <div>
        <h1>{{$t('routes.pod.menuInfo.mediaInformation')}}</h1>
      </div>


      <div class="media-information-wrapper">
        <div class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.fileName')}}</div>
          <div>{{pod.name}}</div>
        </div>

        <div class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.fileType')}}</div>
          <div>{{rawAsset.filetype}}</div>
        </div>

        <div class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.fileSize')}}</div>
          <div>{{(rawAsset.size || 0) | prettyBytes(2, false)}}</div>
        </div>

        <br/>

        <div v-if="isVideoPod" class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.runtime')}}</div>
          <div>{{parseFloat(rawAsset.duration || 0) | videoDuration}}</div>
        </div>

        <br/>

        <div class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.resolution')}}</div>
          <div>{{rawAsset.resolution}}</div>
        </div>

        <div class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.aspectRatio')}}</div>
          <div>{{rawAsset.aspect}}</div>
        </div>

        <div v-if="isVideoPod" class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.videoCodec')}}</div>
          <div>{{rawAsset.codec}}</div>
        </div>

        <div v-if="isVideoPod" class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.bitrate')}}</div>
          <div>{{rawAsset.bitrate}}</div>
        </div>

        <div v-if="isVideoPod" class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.fps')}}</div>
          <div>{{ rawAsset.framerate ? parseFloat(rawAsset.framerate).toFixed(2) : ''}}</div>
        </div>

        <br/>

        <div v-if="isVideoPod" class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.audioCodec')}}</div>
          <div>{{rawAsset.audiocodec}}</div>
        </div>

        <div v-if="isVideoPod" class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.audioChannels')}}</div>
          <div>{{rawAsset.audiochannels}}</div>
        </div>

        <div v-if="isVideoPod" class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.audioBitrate')}}</div>
          <div>{{rawAsset.audiobitrate}}</div>
        </div>

        <br/>

        <div v-if="pod.createdby && pod.createdby.name" class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.createdBy')}}</div>
          <div>{{pod.createdby.name}}</div>
        </div>

        <div class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.creationDate')}}</div>
          <div>{{pod.createdat | isoToDatetime}}</div>
        </div>

        <div v-if="pod.updatedby && pod.updatedby.name" class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.lastUpdatedBy')}}</div>
          <div>{{pod.updatedby.name}}</div>
        </div>

        <div class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.lastUpdatedDate')}}</div>
          <div>{{pod.updatedat | isoToDatetime}}</div>
        </div>

        <div class="d-flex pod-information-row">
          <div>{{$t('routes.pod.menuInfo.serviceUsed')}}</div>
          <div></div>
        </div>

      </div>

      <br/>

      <div v-if="!disableNotes" class="d-flex" style="height: 36px;">
        <div class="flex-grow-1 d-flex align-center">
          <h3>{{ $t('routes.pod.menuInfo.note') }}</h3>
        </div>
        <div v-show="isNoteChanged" class="flex-grow-0 text-right" >
          <v-btn tile icon v-on:click.prevent="noteCancel()">
            <v-icon small color="primary">mdi-cancel</v-icon>
          </v-btn>

          <v-btn tile icon v-on:click.prevent="noteSave()">
            <v-icon small color="primary">mdi-floppy</v-icon>
          </v-btn>
        </div>
      </div>

      <div v-if="!disableNotes" class="pb-2">
        <div>
          <v-textarea
              class="media-list-notes-textarea"
              v-bind:disabled="!$canControlNodeAndPod()"
              v-model="noteModel"
              v-bind:success="noteSuccess"
              v-bind:error="noteError"
              v-on:focus="setNeutral"
              solo flat
              dense
              rows="6"
              hide-details="true"
              value=""
              :aria-label="$t('routes.pod.menuInfo.note')"
          />
        </div>
      </div>



      <!-- <div class="mt-5">
        <vue-json-pretty
          v-bind:data="metadata"
          v-bind:deep="2"
          v-bind:showDoubleQuotes="false">
        </vue-json-pretty>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "InformationToolbar",
  props: {
    pod: Object,
    disableNotes: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data: function() {
    return {
      noteModel: "",
      noteSuccess: false,
      noteError: false,
    };
  },
  mounted: function() {
    this.noteModel = this.pod ? this.pod.note : "";
  },
  methods: {
    noteCancel: function() {
      this.noteModel = this.pod.note;
    },
    noteSave: function() {
      const UPDATEPOD = this.$store.getters.keywords.POD.UPDATEPOD;
      let copiedPod = JSON.parse(JSON.stringify(this.pod));   // deep copy
      copiedPod.note = this.noteModel;

      var self = this;
      this.$store.dispatch(UPDATEPOD, copiedPod)
      .then(function() {
        self.setSuccess(self.$t('routes.pod.menuInfo.noteUpdated'));
        self.noteModel = self.pod.note;
      })
      .catch(function() {

        self.setError();
      });
    },
    setError: function() {
      this.noteSuccess = false;
      this.noteError = true;
    },
    setSuccess: function() {
      this.noteSuccess = true;
      this.noteError = false;
    },
    setNeutral: function() {
      this.noteSuccess = false;
      this.noteError = false;
    }
  },
  computed: {
    // pod: function() {
    //   const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
    //   return this.$store.getters[GETVIEWPOD];
    // },
    rawAsset: function() {
      if (this.pod && this.pod.asset && this.pod.asset.raw && this.pod.asset.raw[0]) {
        return this.pod.asset.raw[0];
      }
      return {};
    },
    metadata: function() {
      if (this.rawAsset.metadata) {
        if (typeof this.rawAsset.metadata == 'string') {
          return JSON.parse(this.rawAsset.metadata);
        }

        return this.rawAsset.metadata;
      }
      return {};
    },
    isNoteChanged: function() {
      return this.pod && (this.noteModel !== this.pod.note);
    },
    isVideoPod: function() {
      const ISVIDEOPOD = this.$store.getters.keywords.POD.ISVIDEOPOD;
      return this.$store.getters[ISVIDEOPOD];
    },
  }
}
</script>

<style>

.pod-information-row {
  width: 100%;
}

.pod-information-row > div {
  width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pod-information-row > div:first-child {
  font-weight: 600;
}

.pod-information-row > div:last-child {
  color: default;
}

.media-information-wrapper {
  padding: 20px;
  border-radius: 10px;
}

.theme--dark .media-information-wrapper {
  background-color: #2f3640;
}

.theme--light .media-information-wrapper {
  background-color: #e6ecf1;
}
</style>
