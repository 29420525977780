// import Vue from 'vue'
import { subject } from '@casl/ability';


class Permissions {
  constructor() {
    // if (window.localStorage.abilities) {
    //   const abilities = JSON.parse(window.localStorage.abilities);
    //   Vue.$ability.update(abilities);
    // }
  }

  install(Vue) {
    // if (window.localStorage.abilities) {
    //   const abilities = JSON.parse(window.localStorage.abilities);
    //   Vue.$ability.update(abilities);
    // }


    // Permissions contested to specific node
    Vue.prototype.$canDo = function (verbName, subjectName) {
      if (window.localStorage.contextnodeid) {
        const contextnodeid = window.localStorage.contextnodeid || undefined;
        const castedSubject = subject(subjectName, {rootnodeid: contextnodeid});

        return this.$can(verbName, castedSubject);
      }

      return this.$can(verbName, subjectName);
    };

    // Permissions contested to specific node
    Vue.prototype.$allowedTo = function (verbName, subjectName, accessPointId) {
      const castedSubject = subject(subjectName, { rootnodeid: accessPointId });
      return this.$can(verbName, castedSubject);
    };

    Vue.prototype.$updateAbilities = function(abilities) {
      // The abilities are also updated in the App.vue file at startup
      this.$ability.update(abilities);
      window.localStorage.abilities = JSON.stringify(abilities);
    };

    Vue.prototype.$canManageAll = function() {
      // only administrators have this permission
      return this.$can('manage', 'all');
    };

    Vue.prototype.$canListUser = function() {
      return this.$canDo('list', 'User');
    };

    Vue.prototype.$canControlUser = function() {
      return this.$canDo('control', 'User');
    };

    Vue.prototype.$canBelongstoTeam = function() {
      return this.$canDo('belongsto', 'Team');
    };

    Vue.prototype.$canControlTenantConfig = function() {
      return this.$canDo('control', 'TenantConfiguration');
    };

    Vue.prototype.$canListNodeAndPod = function() {
      return this.$canDo('list', 'NodeAndPod');
    };

    Vue.prototype.$canControlNodeAndPod = function() {
      return this.$canDo('control', 'NodeAndPod');
    };

    Vue.prototype.$canDownloadNodeAndPod = function() {
      return this.$canDo('download', 'NodeAndPod');
    };

    Vue.prototype.$canListAsset = function() {
      return this.$canDo('list', 'Asset');
    };

    Vue.prototype.$canUploadFileAndAsset = function() {
      return this.$canDo('upload', 'FileAndAsset');
    };

    Vue.prototype.$canArchiveNodeAndPod = function() {
      return this.$canDo('archive', 'NodeAndPod');
    };

    Vue.prototype.$canListShareLinks = function() {
      return this.$canDo('list', 'ShareLinks');
    };

    Vue.prototype.$canControlShareLinks = function() {
      return this.$canDo('control', 'ShareLinks');
    };

    Vue.prototype.$canListPodService = function() {
      return this.$canDo('list', 'PodService');
    }

    Vue.prototype.$canControlPodService = function() {
      return this.$canDo('control', 'PodService');
    }

    Vue.prototype.$canRunFreeService = function() {
      return this.$canDo('run', 'FreeService');
    }

    Vue.prototype.$canRunPaidService = function() {
      return this.$canDo('run', 'PaidService');
    }

    Vue.prototype.$isTeamUser = function() {
      return this.$canBelongstoTeam();
    }

    Vue.prototype.$isClientUser = function() {
      return !this.$canBelongstoTeam();
    }

    Vue.prototype.$isFCPExtension = function() {
      if (window.webkit && window.webkit.messageHandlers) {
        return true;
      }
      return false;
    }

    Vue.prototype.$isAdobeExtension = function() {
      if (window.cep) {
        return true;
      }
      return false;
    }

    Vue.prototype.$belongsToTeam = function(accessPointId) {
      return this.$allowedTo('belongsto', 'Team', accessPointId);
    };
  }
}

export default new Permissions();
