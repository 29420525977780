<template>
  <v-navigation-drawer
    app
    clipped
    fixed
    right
    bottom
    mobile-breakpoint="960"
    v-model="drawer"
    class="pod-sidemenu"
    width="480"
    style=""
    ref="drawerElement"
  >
    <v-btn-toggle
      :value="getActiveToolbar"
      mandatory
      max-width="100%"
      class="d-flex"
      background-color="podSidemenuButtons"
      color="primary"
      borderless
      data-cy="podSidemenuButtons-list"
    >
      <v-btn
        text
        icon
        class="flex-grow-1"
        @click="setActiveToolbar(0)"
        :aria-label="$t('routes.pod.menuInfo.mediaInformation')"
        data-cy="podSidemenuButtons-info"
      >
        <v-icon :color="getActiveToolbar == 0 ? 'primary' : 'default'">mdi-information</v-icon>
      </v-btn>

      <v-btn
        v-if="$canListPodService()"
        text
        icon
        :disabled="!isVideoPod && !isAudioPod"
        @click="setActiveToolbar(1)"
        class="flex-grow-1"
        :aria-label="$t('routes.pod.menuComment.comments')"
        data-cy="podSidemenuButtons-comment"
      >
        <v-icon :color="getActiveToolbar == 1 ? 'primary' : 'default'">mdi-comment</v-icon>
      </v-btn>

      <v-btn
          v-if="$canListPodService()"
          text
          icon
          class="flex-grow-1"
          @click="setActiveToolbar(2)"
          :aria-label="$t('routes.pod.labels')"
          data-cy="podSidemenuButtons-label"
      >
        <v-icon :color="getActiveToolbar == 2 ? 'primary' : 'default'">mdi-label</v-icon>
      </v-btn>

      <v-btn
          v-if="$canListPodService()"
          text
          icon
          class="flex-grow-1"
          @click="setActiveToolbar(3)"
          :disabled="!isVideoPod && !isAudioPod"
          :aria-label="$t('routes.pod.transcripts')"
          data-cy="podSidemenuButtons-transcribe"
      >
        <v-icon :color="getActiveToolbar == 3 ? 'primary' : 'default'">mdi-transcribe</v-icon>
      </v-btn>

      <v-btn
          v-if="$canListPodService()"
          text
          icon
          class="flex-grow-1"
          @click="setActiveToolbar(4)"
          :disabled="!isVideoPod && !isAudioPod"
          :aria-label="$t('routes.pod.assetSubtitle')"
          data-cy="podSidemenuButtons-subtitles"
      >
        <v-icon :color="getActiveToolbar == 4 ? 'primary' : 'default'">mdi-subtitles</v-icon>
      </v-btn>

      <v-btn
        v-if="$canListAsset()"
        text
        icon
        class="flex-grow-1"
        @click="setActiveToolbar(5)"
        data-cy="podSidemenuButtons-mediaAssets"
        :aria-label="$t('routes.pod.mediaAssets')"
      >
        <v-icon :color="getActiveToolbar == 5 ? 'primary' : 'default'">mdi-image-multiple-outline</v-icon>
      </v-btn>
    </v-btn-toggle>

    <div v-if="pod" class="pod-sidemenu-content-wrapper">
      <InformationToolbar v-if="getActiveToolbar == 0" :pod="pod" :disableNotes="false">
      </InformationToolbar>

      <CommentsToolbar v-if="getActiveToolbar == 1 && $canListPodService() && (isVideoPod || isAudioPod)">
      </CommentsToolbar>

      <LabelsToolbar v-if="getActiveToolbar == 2 && $canListPodService()">
      </LabelsToolbar>

      <TranscriptsToolbar v-if="getActiveToolbar == 3 && $canListPodService()">
      </TranscriptsToolbar>

      <SubtitlesToolbar v-if="getActiveToolbar == 4 && $canListPodService()">
      </SubtitlesToolbar>

      <AssetsToolbar v-if="getActiveToolbar == 5 && $canListAsset()">
      </AssetsToolbar>
    </div>

  </v-navigation-drawer>
</template>

<script>
import MimetypeHelper from '../../../../components/MimetypeHelper.js'

import InformationToolbar from './informationToolbar/InformationToolbar.vue'
import CommentsToolbar from './commentsToolbar/CommentsToolbar.vue'
import LabelsToolbar from './labelsToolbar/LabelsToolbar.vue'
import TranscriptsToolbar from './transcriptsToolbar/TranscriptsToolbar.vue'
import SubtitlesToolbar from './subtitlesToolbar/SubtitlesToolbar.vue'
import AssetsToolbar from './assetsToolbar/AssetsToolbar.vue'

export default {
  name: "PodSidemenu",
  components: {
    InformationToolbar,
    CommentsToolbar,
    LabelsToolbar,
    TranscriptsToolbar,
    SubtitlesToolbar,
    AssetsToolbar,
  },
  data() {
    return {
      drawer: null,
    };
  },
  watch: {
    getSidemenuToggle() {
      this.drawer = !this.drawer;
    },
    // activeSidemenuToggle(newValue) {
    //   this.setActiveToolbar(newValue);
    // }
    '$route.query.menu': function() {
      this.showAssetsFromURL();
    }
  },
  mounted() {
    this.showAssetsFromURL();
  },
  methods: {
    setActiveToolbar(value) {
      const SETACTIVETOOLBAR = this.$store.getters.keywords.POD.SETACTIVETOOLBAR;
      this.$store.commit(SETACTIVETOOLBAR, value);
    },
    showAssetsFromURL() {
      if (this.$route.query.menu === 'assets' && this.$canListAsset()) {
        this.setActiveToolbar(5)
        this.drawer = true;

        const query = Object.assign({}, this.$route.query);
        delete query.menu;
        this.$router.replace({ query });
      }
    }
  },
  computed: {
    pod: function() {
      const GETVIEWPOD = this.$store.getters.keywords.POD.GETVIEWPOD;
      return this.$store.getters[GETVIEWPOD];
    },
    isVideoPod: function() {
      return MimetypeHelper.isMimetypeVideo(this.pod);
    },
    isAudioPod: function() {
      return MimetypeHelper.isMimetypeAudio(this.pod);
    },
    isImagePod: function() {
      return MimetypeHelper.isMimetypeImage(this.pod);
    },
    getSidemenuToggle() {
      const GETSIDEMENUTOGGLE = this.$store.getters.keywords.MAIN.GETSIDEMENUTOGGLE;
      return this.$store.getters[GETSIDEMENUTOGGLE];
    },
    getActiveToolbar() {
      const GETACTIVETOOLBAR = this.$store.getters.keywords.POD.GETACTIVETOOLBAR;
      return this.$store.getters[GETACTIVETOOLBAR];
    },
  }
}
</script>

<style>

.pod-sidemenu {
  margin-top: 80px;
  margin-left: 4px;
  border-radius: 7px;
  /* padding-top: 80px; */
  max-height: calc(100% - 84px) !important;
  padding: 8px;
}

@media only screen and (max-width: 960px) {
  .pod-sidemenu {
    margin-top: 80px;
    margin-left: 0px;
    border-radius: 0px;
    /* padding-top: 84px; */
    max-height: calc(100% - 84px) !important;
  }
}

.theme--dark.pod-sidemenu {
  background-color: #232b36 !important;
}

.theme--light.pod-sidemenu {
  background-color: #FFFFFF !important;
}

.pod-sidemenu-content-wrapper {
  padding: 10px;
}
</style>
